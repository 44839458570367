export const features = [
    {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-feat001.png?alt=media&token=40f2e74a-ed7a-4ac5-b519-f41320961919",
        desc: "Crea un modelo de ingresos/negocio disruptivo y que funcione en paralelo a tu operación actual.",
        descEN: "Create a business / revenue model that is disruptive and works in parallel with your current operation.",
    },
    {
        id: 2,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-feat002.png?alt=media&token=a3d6f15a-f644-4909-9bfa-aba38d4e8e54",
        desc: "Accede a nuevas tecnologías, mentalidades y metodologías de trabajo.",
        descEN: "Access new technologies, mindsets and work methodologies.",
    },
    {
        id: 3,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-feat003.png?alt=media&token=3a2a5828-4c9b-4f6a-929e-aef004cb107b",
        desc: "Estimula a tu equipo humano actual a desarrollarse de manera colaborativa.",
        descEN: "Encourage your current team to develop collaboratively.",
    },
    {
        id: 4,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-feat004.png?alt=media&token=2d4a553a-0fa9-4e7b-853a-6c6cc1a480f2",
        desc: "Prepara a tu empresa para futuras crisis y situaciones inesperadas.",
        descEN: "Prepare your company for future crises and unexpected situations.",
    },
]

export const portfolio = [
    {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/webp%2Funicornia-teleboing-img.webp?alt=media&token=fc1baa7d-42eb-4d64-845c-921bbb164513",
        title: "TELEBOING",
        desc: "A multiverse-content streaming platform that offers fast, easy and personalized access to a wide variety of short films, original series, stand-up comedy sessions in spanish-only language and based on real, fascinating and entertaining stories whose outcome you can control. [United States]",
        logo: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/Unicornia-logo-teleboing.svg?alt=media&token=05605a80-9e24-412b-b962-b1baa7b603af",
        bg: "A59C94",
        www: "https://teleboing.com",
        textwww: "www.teleboing.com",
    },
    {
        id: 2,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/webp%2Funicornia-morefood-img.webp?alt=media&token=4687882b-0814-47fc-ab2b-0f4eca66c29b",
        title: "morefood",
        desc: "A.I. Plant-based Food-ingredient Design Company.[Colombia]",
        logo: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-morefood.svg?alt=media&token=54c05b50-a718-41d9-9c3b-0b3964af461e",
        bg: "f4c430",
        www: "https://uniqornio.com/morefood",
        textwww: "Pitch Deck",
    },
    {
        id: 3,
        img: "https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fdemoqrata-cover-ads.jpg?alt=media&token=04c258eb-96a7-4323-a5ed-078c7d119dc9",
        title: "DEMOQRATA",
        desc: "TV original-content streaming platform based on predictive micro-CDN by artificial intelligence that works without free-to-air, without cables, without internet. [Peru]",
        logo: "https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2FLogo4-demoqrata.png?alt=media&token=9e30694b-bcd0-4157-9eb4-0077227c2545",
        bg: "000000",
        font: "ffffff",
        www: "https://www.demoqrata.com",
        textwww: "www.demoqrata.com",
    },
    {
        id: 5,
        img: "https://firebasestorage.googleapis.com/v0/b/unicorn-ia.appspot.com/o/resources%2Flogos%2FUnishoper-Cover02.webp?alt=media&token=4614b455-5507-490c-82d2-45dae820fd38",
        title: "unishoper",
        desc: "Shopping recommendations based on real-world emotional reactions. [Denmark]",
        logo: "https://firebasestorage.googleapis.com/v0/b/unicorn-ia.appspot.com/o/resources%2Flogos%2FUnishoper.svg?alt=media&token=37b10235-3c0c-45ce-b5b6-b095492f2afd",
        bg: "52a17d",
        www: "https://unishoper.com",
        textwww: "www.unishoper.com",
    },
    {
        id: 6,
        img: "https://firebasestorage.googleapis.com/v0/b/almerqao.appspot.com/o/resources%2Falmerqao-cover-app-resize.png?alt=media&token=0a3fdbb9-215c-49ca-b2ae-080baa6015fe",
        title: "almerqao",
        desc: "Food and grocery predictive app where you can quickly buy all the products that you and your home need, and receive them every weekend wherever you want. [Mexico]",
        logo: "https://firebasestorage.googleapis.com/v0/b/almerqao.appspot.com/o/resources%2FLogo-almerqao-white.svg?alt=media&token=0473c707-3590-415a-b368-644589c25de4",
        bg: "FFA500",
        www: "https://almerqao.web.app",
        textwww: "almerqao.web.app",
    },
    {
        id: 7,
        img: "https://firebasestorage.googleapis.com/v0/b/unicorn-ia.appspot.com/o/resources%2Fportfolio%2FSuperpix-cover.png?alt=media&token=d973c33e-7c49-45ef-8d30-b9e2b374518e",
        title: "Superpix",
        desc: "Data analysis platform to generate custom AI-generated images from cutting-edge algorithm that is being meticulously trained to Prompt automatically based on real-time analysis of the market, consumer behavior, buying trends, and evolving needs. [Latinamerica]",
        logo: "https://firebasestorage.googleapis.com/v0/b/unicorn-ia.appspot.com/o/resources%2Fportfolio%2Flogo-Superpix.svg?alt=media&token=185a19a0-ee89-4f59-bba8-76ef749b6e1d",
        bg: "000000",
        font:"ffffff",
        www: "https://uniqornio.com/superpix",
        textwww: "Pitch Deck",
    }
]

export const team = [
    {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/Unicornia-JorgeToledo.png?alt=media&token=7c87f4e1-0209-445a-8510-62008a9d1800",
        name: "Jorge Toledo-Chacón",
        title: "CEO Founder",
        fullDesc: "I do not believe in the status quo or in the comfort zone, I firmly believe in invention, entrepreneurship and innovation. Life and my enormous curiosity turned me by experimentation into a neuroscientist, so to understand the human mind in 2010 I founded Insighter®, where I managed to decipher the unconscious behavior of more than 200,000 individuals, I've discovered 5,612 gestural and corporal signals that reveal the human being, hit at 98.7% of accuracy the reading of nonverbal language, and where -in 2013- I managed to invent the first algorithm that predicts human behavior in real time, which I call Espectacularity®.",
        desc: "I do not believe in the status quo or in the comfort zone, I firmly believe in invention, entrepreneurship and innovation.",
        linkedinUrl: "https://www.linkedin.com/in/jorgetoledochacon/",
    },
    {
        id: 2,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/Unicornia-KristianLund.png?alt=media&token=48aa089e-33b6-49ee-8df8-11806922e740",
        name: "Kristian Lund",
        title: "Senior Business Consultant",
        fullDesc: "I personally meet with 80-100 startups and SMEs each year where we dive deep into anything from business modelling, team challenges, investor/partner pitching, international launch as well as public funding opportunities. I bet I can help you move forward - and if not, I have more than 50 colleagues with loads of knowledge, expertise and a network that surely can!",
        desc: "I personally meet with 80-100 startups and SMEs each year where we dive deep into anything from business modelling, team challenges, investor/partner pitching, international launch as well as public funding opportunities.",
        linkedinUrl: "https://www.linkedin.com/in/kristianlunddk/",
    },
    {
        id: 3,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/Unicornia-FernandoLamas.png?alt=media&token=854cdac7-654f-450e-b2d0-479b82428ce7",
        name: "Fernando Lamas",
        title: "Finance and Capital",
        fullDesc: "Focused on strategies of growth, international development, and transformation with an internal and external focus. More than 12 years of experience in boards of directors and senior management positions in the private sector and NGOs, leading financial strategies, and value generation. High level of negotiation, critical thinking and analysis, projection capacity and quick problem solving.",
        desc: "Focused on strategies of growth, international development, and transformation with an internal and external focus. More than 12 years of experience in boards of directors and senior management positions.",
        linkedinUrl: "https://www.linkedin.com/in/fernandolamasn/",
    },
    {
        id: 4,
        img: "https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/Unicornia-LuisBravo.png?alt=media&token=93749998-a51e-4b64-9b80-613386b0683d",
        name: "Luis Bravo M.",
        title: "Future Technologies Consultant",
        fullDesc: "I see technology as the main driving force behind human behavioural change. Technology is deeply and rapidly transforming lifestyles, purposes and beliefs. As a marketer, I already depend professionally of Google’s search algorithm, e-mail spam filters, PPC bid engines and so much more Martech - IMHO, immersive technology (virtual & augmented reality) will accelerate this transformation dramatically.",
        desc: "I see technology as the main driving force behind human behavioural change. Technology is deeply and rapidly transforming lifestyles, purposes and beliefs.",
        linkedinUrl: "https://www.linkedin.com/in/luismartins/",
    },
]