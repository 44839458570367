import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Logo_Uniqornio.svg";


function Nav() {

    const [show, handleShow] = useState(false);

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    const navigate = useNavigate();

    const calendly = (e) => {
        e.preventDefault();
        navigate('/calendly');
    };

    const tohome = (e) => {
        e.preventDefault();
        navigate('/');
    };

    return (
        <div className={`nav ${show && "nav__black"}`} >
            <div className="nav__contents">
                <div className="nav__contents__logo" >
                <img
                    onClick={tohome} 
                    className='nav__logo'
                    src={logo}
                    alt="uniqornio" title=""
                />
                </div>
                <div className="nav__contents__menu">
                    <Link to="uniqornio" smooth={true} duration={500} >
                        <span className="nav__contents__menu__span">Go to top</span>
                    </Link>
                    <Link to="portfolio" smooth={true} duration={500} >
                        <span className="nav__contents__menu__span">Portfolio</span>
                    </Link>
                    <Link to="team" smooth={true} duration={500} >
                        <span className="nav__contents__menu__span">Team</span>
                    </Link>
                    <span className="nav__contents__menu__span" onClick={calendly} >Get a videocall</span>
                    {/* <Link to="footer" smooth={true} duration={500} >
                        <span className="nav__contents__menu__span">Social media</span>
                    </Link> */}
                </div>
            </div>
            
        </div>
    )
}

export default Nav;
